import Layout, { CardStyled, HeadStyled } from '../../components/Layout';
import { fetchCompras, selector } from '../../redux/slices/ComprasSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export default function Compras() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { compras, loading } = useSelector(selector);
    const [searchText, setSearchText] = useState('');
    const [filter, setFilter] = useState("Todas");
    const [listFiltered, setListFiltered] = useState([]);

    useEffect(() => {
        dispatch(fetchCompras());
    }, [])

    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        const result = compras?.filter(compra =>
            compra.email.toLowerCase().includes(searchText.toLowerCase()) ||
            compra.tituloObra.toLowerCase().includes(searchText.toLowerCase())
        );
        setListFiltered(result);
    }, [searchText, compras]);

    useEffect(() => {
        const result = compras?.filter(compra => compra.estado?.includes(filter));
        setListFiltered(result);
    }, [filter, compras]);

    function exportToExcel(data) {
        function formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}-${month}-${year} ${hours}:${minutes}`;
        }
        const dataToExport = data.map(item => {
            return {
                ...item,
                fechaCutte: formatTimestamp(item.fechaObra)
            };
        });
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, 'compras.xlsx');
    }

    return (
        <Layout>
            <div className="head-compras">
                <input onChange={onChangeSearchText} style={{ width: '70%', fontSize: 45 }} placeholder='Buscar'></input>
                <div>
                    <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                        <option value="CONFIRMADA">Confirmadas</option>
                        <option value="Pendiente de pago">Pendientes de pago</option>
                        <option value="Devolucion solicitada">Devolucion solicitada</option>
                        <option value="Devolucion confirmada">Devolucion confirmada</option>
                        <option value="Sin confirmar">Sin confirmar</option>
                        <option value="Todas">Todas</option>
                    </select>
                    <pre>Total: {filter === "Todas" ? compras?.length : listFiltered?.length}</pre>
                </div>
                <button onClick={() => exportToExcel(listFiltered.length > 0 ? listFiltered : compras)}>Exportar a Excel</button>
            </div>
            <HeadStyled>
                <span style={{ width: "30%" }}>Fecha</span>
                <span style={{ width: "30%" }}>Función</span>
                <span style={{ width: "22%" }}>Email</span>
                <span style={{ width: "8%" }}>Estado</span>
                <span style={{ width: "8%" }}>Importe</span>
            </HeadStyled>
            {!loading ? <Table /> : <h3>Cargando...</h3>}
        </Layout>
    );

    function openEdit(e, id) {
        e.preventDefault();
        navigate(`/compras/${id}`)
    }

    function Card({ compra }) {
        const { fechaObra, email, tituloObra, estado, paymentIntent } = compra;
        return (
            <CardStyled onClick={(e) => { openEdit(e, compra._id) }}>
                <span style={{ width: "30%" }}>{
                    new Date(fechaObra).toLocaleDateString("es-ES", {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })}</span>
                <span style={{ width: "30%" }}>{tituloObra}</span>
                <span style={{ width: "22%" }}>{email}</span>
                <span style={{ width: "8%" }}>{estado}</span>
                <span style={{ width: "8%" }}>{paymentIntent.amount / 100}€</span>
            </CardStyled>
        )
    }

    function Table() {
        if (searchText?.length === 0 && filter === "Todas") return compras?.map((compra, index) => {
            return <Card key={index} compra={compra} />
        })
        else if (listFiltered?.length > 0) return listFiltered.map((compra, index) => {
            return <Card key={index} compra={compra} />
        })
        else return <h2>Sin compras que mostrar</h2>
    }

}





