import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "./context";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Escuela from './pages/Escuela';
import Sala from './pages/Sala';
import AddObra from './pages/Sala/Add';
import EditObra from './pages/Sala/Edit';
import ObrasNow from './pages/Sala/Now';
import AddCurso from './pages/Escuela/Add';
import EditCurso from './pages/Escuela/Edit';
import Talleres from "./pages/Escuela/Talleres";
import Cursos from "./pages/Escuela/Cursos";
import Equipo from "./pages/Equipo";
import AddProfesor from "./pages/Equipo/Add";
import EditProfesor from "./pages/Equipo/Edit";
import EditCompra from "./pages/Compras/Edit";
import Compras from "./pages/Compras";
import Devolucion from "./components/CompraForm/devolucion";


// PRE-SHOW
// TODO: Añadir obras Abril y borrar las de test
// TODO: Desplegar en entorno de pruebas .gmonzon.com

// FUNCIONALIDAD
// TODO: Implementar gestión de Logos
// TODO: Importación de contactos en ipzmarketing


export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path=""
            element={
              <PrivateRoute>
                <Navigate to="sala/now" replace />
              </PrivateRoute>
            }
          />
          <Route
            path="sala"
            element={
              <PrivateRoute>
                <Sala />
              </PrivateRoute>
            }
          />
          <Route
            path="sala/add"
            element={
              <PrivateRoute>
                <AddObra />
              </PrivateRoute>
            }
          />
          <Route
            path="sala/now"
            element={
              <PrivateRoute>
                <ObrasNow />
              </PrivateRoute>
            }
          />
          <Route
            path="sala/:id"
            element={
              <PrivateRoute>
                <EditObra />
              </PrivateRoute>
            }
          />
          <Route
            path="escuela/"
            element={
              <PrivateRoute>
                <Escuela />
              </PrivateRoute>
            }
          />
          <Route
            path="escuela/cursos"
            element={
              <PrivateRoute>
                <Cursos />
              </PrivateRoute>
            }
          />
          <Route
            path="escuela/talleres"
            element={
              <PrivateRoute>
                <Talleres />
              </PrivateRoute>
            }
          />
          <Route
            path="escuela/add"
            element={
              <PrivateRoute>
                <AddCurso />
              </PrivateRoute>
            }
          />
          <Route
            path="escuela/:id"
            element={
              <PrivateRoute>
                <EditCurso />
              </PrivateRoute>
            }
          />
          <Route
            path="equipo"
            element={
              <PrivateRoute>
                <Equipo />
              </PrivateRoute>
            }
          />
          <Route
            path="equipo/add"
            element={
              <PrivateRoute>
                <AddProfesor />
              </PrivateRoute>
            }
          />
          <Route
            path="equipo/:id"
            element={
              <PrivateRoute>
                <EditProfesor />
              </PrivateRoute>
            }
          />
          <Route
            path="compras"
            element={
              <PrivateRoute>
                <Compras />
              </PrivateRoute>
            }
          />
          <Route
            path="compras/:id"
            element={
              <PrivateRoute>
                <EditCompra />
              </PrivateRoute>
            }
          />
          <Route
            path="compras/:id/devolucion"
            element={
              <PrivateRoute>
                <Devolucion />
              </PrivateRoute>
            }
          />
          <Route path="login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
