import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

const WEEKDAYS_LONG = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
];

const WEEKDAYS_SHORT = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];

export default class Fechas extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTimeClick = this.handleTimeClick.bind(this);
        this.cleanDates = this.cleanDates.bind(this);
        this.deleteDates = this.deleteDates.bind(this);
        this.state = {
            selectedDays: [],
            selectedUTC: [],
            selectedTime: new Date(),
        };
    }

    handleDayClick(day, { selected }) {
        const selectedDays = this.state.selectedDays.concat();
        const selectedUTC = this.state.selectedUTC.concat();
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
            selectedUTC.splice(selectedIndex, 1);
        } else {
            // Change time from "selectedTime"
            const timeFull = this.state.selectedTime;
            const h = timeFull.getHours();
            const m = timeFull.getMinutes();
            day.setHours(h, m, 0, 0);
            // Convert to UTC for no axios conversion
            const dayUTC = day.getTime();
            // Check if date already exists and push
            if (this.props.fechas) {
                if (!this.props.fechas.includes(dayUTC)) selectedUTC.push(dayUTC)
            } else {
                selectedUTC.push(dayUTC)
            }
            // Push into selectedDays too for no funcionality loose
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
        this.setState({ selectedUTC });
    }

    cleanDates() {
        const selectedDays = [];
        this.setState({ selectedDays });
        const selectedUTC = [];
        this.setState({ selectedUTC });
        return;
    }

    deleteDates() {
        this.props.remove()
    }

    handleSubmit() {
        this.state.selectedUTC.length > 0 && this.state.selectedUTC.map(utc => {
            this.props.push('fechas', { fecha: utc, compras: 0, reservas: 0 })
        })
        this.cleanDates();
    }

    handleTimeClick(time) {
        this.setState({
            selectedTime: time
        })
    }

    render() {
        return (
            <DatePickerStyled>
                <h3>Fechas</h3>
                <pre>Seleccionar hora antes de <br />pulsar sobre el calendario<br /></pre>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        value={this.state.selectedTime}
                        onChange={this.handleTimeClick}
                    />
                </MuiPickersUtilsProvider>
                <DayPicker
                    selectedDays={this.state.selectedDays}
                    onDayClick={this.handleDayClick}
                    selector={this.state.selectedDays}
                    months={MONTHS}
                    weekdaysLong={WEEKDAYS_LONG}
                    weekdaysShort={WEEKDAYS_SHORT}
                    firstDayOfWeek={1}
                />
                <div>
                    <span id="btn-image" onClick={this.handleSubmit}>Añadir</span>
                    <span id="btn-image" onClick={this.deleteDates}>Limpiar</span>
                </div>


                <div id="fechas-edit">
                    <span className="fecha-edit">Fecha&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="fecha-num">&nbsp;&nbsp;&nbsp;&nbsp;Compras&nbsp;&nbsp;</span>
                    <span className="fecha-num">Reservas</span>
                </div>


                <FieldArray name="fechas">
                    {({ fields }) => (
                        <div>
                            {fields.map((name, index) => {
                                let date
                                if (fields.value[index].fecha) date = new Date(fields.value[index].fecha).toLocaleDateString("es-ES", { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
                                else return null
                                
                                return <div key={index}>
                                    <span>{date || ""}</span>

                                    <Field name={`${name}.compras`} component="input" id="input-num" />

                                    <Field name={`${name}.reservas`} component="input" id="input-num" />

                                    <button type="button" onClick={() => fields.remove(index)}>
                                        ❌
                                    </button>
                                </div>
                            })}

                        </div>
                    )}
                </FieldArray>
            </DatePickerStyled>
        );
    }
}
const DatePickerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 100%;


    #fechas-edit{
        margin-top: 1vw;
        font-weight: bold;
    }

    .fecha-edit-h{
        width: 250px;
        font-weight: bold;
    }

    .fecha-num-h{
        width: 50px
        font-weight: bold;
    }
    .DayPicker{
        color: black;
        font-size: 1.5vw;
    }
    .DayPicker-Day--today{
        color: #f46d2b;
        :hover{
            color: #f46d2b;
        }
    }
    DayPicker-Day--selected{
        background-color: #f46d2b;
    }
`

const ButtonDatesStyled = styled.span`
    margin: 1%;
    padding: 1%;
    border: 1px solid black;
    border-radius: 25px;
    :hover{
        cursor:pointer;
    }
`