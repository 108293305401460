import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAPI} from "../../api";

export const fetchTodos = createAsyncThunk(
    "Cursos/fetchTodos",
    async () => {
       return await getAPI('cursos')
          .then((data) => {return data});
    }
);

export const fetchCursos = createAsyncThunk(
  "Cursos/fetchCursos",
  async () => {
     return await getAPI('cursos/cursos')
        .then((data) => {return data});
  }
);

export const fetchTalleres = createAsyncThunk(
  "Cursos/fetchTalleres",
  async () => {
     return await getAPI('cursos/talleres')
        .then((data) => {return data});
  }
);

export const fetchCurso = createAsyncThunk(
    "Cursos/fetchCurso",
    async (id, thunkAPI) => {
       return await getAPI(`cursos/${id}`)
          .then((data) => {
              return data
          });
    }
);


export const CursosSlice = createSlice({
  name: "Cursos",
  initialState: {
    Todos: [],
    Cursos: [],
    Talleres: [],
    loading: false,
    curso: {},
  },
  reducers: {},
  extraReducers: {

    [fetchCurso.pending]: (state) => {
      state.loading = true;
    },
    [fetchCurso.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.curso = payload;
    },
    [fetchCurso.rejected]: (state) => {
      state.loading = true;
    },

    [fetchCursos.pending]: (state) => {
      state.loading = true;
    },
    [fetchCursos.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Cursos = payload;
    },
    [fetchCursos.rejected]: (state) => {
      state.loading = true;
    },

    [fetchTodos.pending]: (state) => {
      state.loading = true;
    },
    [fetchTodos.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Todos = payload;
    },
    [fetchTodos.rejected]: (state) => {
      state.loading = true;
    },

    [fetchTalleres.pending]: (state) => {
      state.loading = true;
    },
    [fetchTalleres.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Talleres = payload;
    },
    [fetchTalleres.rejected]: (state) => {
      state.loading = true;
    },

  },
});


export const {all} = CursosSlice.actions;

export const selector = (state) => state.cursos;

export default CursosSlice.reducer;
