import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import ObraForm from "../../../components/ObraForm";
import { fetchObra, selector } from '../../../redux/slices/ObrasSlice';

// let editorState = {"blocks":[{"key":"9bjtg","text":"Supertesting strong chico malo","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}

export default function App() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { obra, loading } = useSelector(selector);

  useEffect(() => {
    dispatch(fetchObra(id))
  }, []);

  const customValues = {
    idMongo: obra._id,
    id: obra.id,
    titulo: obra.titulo,
    cartel: obra.cartel,
    editorState: obra.sinopsis,
    autores: obra.autores,
    direccion: obra.direccion,
    actores: obra.actores,
    compania: obra.compania,
    precio: obra.precio,
    precio2: obra.precio2,
    precio3: obra.precio3,
    fechas: obra.fechas,
    maxEntradas: obra.maxEntradas,
    reservas: obra.reservas,
    vendidas: obra.vendidas,
    prensa: obra.prensa,
    entradas: obra.entradas,
    duracionMinutos: obra.duracionMinutos,
    esNueva: obra.esNueva,
    publicada: obra.publicada,
  }

  return (
    <Layout>
      {loading ? <h3>Cargando...</h3> : <ObraForm customValues={customValues} editar={true} />}
    </Layout>
  );

}