import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, useAuthDispatch } from "../../context";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useAuthDispatch();
  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let response = await loginUser(dispatch, { email, password });
      if(!response.token){
          document.getElementById("error").innerHTML = "Error al iniciar sesion"
      } else {
          navigate("/");
      }
    } catch (error) {
      document.getElementById("error").innerHTML = "Error al iniciar sesion"
      console.log(error);
    }
  };

  return (
      <div style={{fontSize: "1.3vw"}}>
        <h1>Login</h1>
        <form onSubmit={handleLogin}>
            <label htmlFor="email">Usuario&nbsp;&nbsp;</label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{fontSize: "1.3vw"}}
              />
              <br/>
            <label htmlFor="password">Contraseña&nbsp;&nbsp;</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{fontSize: "1.3vw"}}

              />
              <br/>
            <button style={{fontSize: "1.3vw"}} type="submit">Iniciar sesión</button>
            <p id="error" style={{color: "red"}}></p>
        </form>
      </div>
  );
}

export default Login;