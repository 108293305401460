import Layout, { CardStyled, HeadStyled, HrStyled } from '../../../components/Layout';
import { fetchObrasNow, selector } from '../../../redux/slices/ObrasSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export default function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { listNow, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchObrasNow());
    }, [])

    return (
        <Layout>
            <HeadStyled>
                <span style={{ width: "35%", fontWeight: "bold" }}>Titulo</span>
                <span style={{ width: "35%", fontWeight: "bold"  }}>Fecha</span>
                <span style={{ width: "15%", fontWeight: "bold"  }}>Compradas</span>
                <span style={{ width: "15%", fontWeight: "bold"  }}>Reservadas</span>
            </HeadStyled>
            {!loading || !listNow ? <TableObras /> : <h3>Cargando...</h3>}
        </Layout>
    );

    function openEdit(e, id) {
        e.preventDefault();
        navigate(`/sala/${id}`)
    }


    function Card({ obra }) {
        const { fecha, titulo, compras, reservas } = obra;
        return (
            <CardStyled onClick={(e) => { openEdit(e, obra._id) }}>
                <span style={{ width: "35%" }}>{titulo}</span>
                <span style={{ width: "35%" }}>{new Date(fecha).toLocaleDateString("es-ES", { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
                <span style={{ width: "15%" }}>{compras}</span>
                <span style={{ width: "15%" }}>{reservas}</span>
            </CardStyled>
        )
    }

    function TableObras() {
        if(listNow.length === 0) return <h1>Ninguna obra con fecha próxima</h1>
        return listNow.map((obra, index) => {
            return <Card key={index} obra={obra} />
        })
    }
}