import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { sortCreacionNew, sortCreacionOld } from "../../redux/slices/ObrasSlice";
import { logout, useAuthDispatch } from "../../context";

function MenuSala() {

    const path = window.location.pathname;
    const navigate = useNavigate();


    const addHandle = (e) => {
        e.preventDefault();
        if (window.confirm("Se borrará cualquier cambio no guardado. ¿Desea continuar?")) {
            window.localStorage.removeItem('draftjs-content');
            navigate("/sala/add")
            navigate(0)
        }
    }

    const todasHandle = (e) => {
        e.preventDefault();
        if (window.confirm("Se cargaran todas las funciones existentes, está opcion puede tardar varios minutos. ¿Desea continuar?")) {
            navigate("/sala")
        }
    }

    const nowHandle = (e) => {
        e.preventDefault();
        navigate("/sala/now")
    }

    return (
        <MenuStyled>
            <Link id={path.includes('/sala/add') ? "active" : "inactive"} to="/sala/add" onClick={addHandle}> Añadir </Link>
            <Link id={path.includes('/sala/now') ? "active" : "inactive"} to="/sala" onClick={nowHandle}> Proximas </Link>
            <Link id={path.includes('/sala') && path.length < 6 ? "active" : "inactive"} to="/sala" onClick={todasHandle}> Todas </Link>
        </MenuStyled>
    );
}

function MenuEscuela() {

    const path = window.location.pathname;
    const navigate = useNavigate();

    const addHandle = (e) => {
        e.preventDefault();
        if (window.confirm("Se borrará cualquier cambio no guardado. ¿Desea continuar?")) {
            window.localStorage.removeItem('draftjs-content');
            navigate("/escuela/add")
            navigate(0)
        }
    }

    return (
        <MenuStyled>
            <Link to="" onClick={addHandle} id={path.includes('/escuela/add') ? "active" : "inactive"}> Añadir </Link>
            <Link to="/escuela/cursos" id={path.includes('/escuela/cursos') ? "active" : "inactive"}> Cursos </Link>
            <Link to="/escuela/talleres" id={path.includes('/escuela/talleres') ? "active" : "inactive"}> Talleres </Link>
            <Link to="/escuela" id={path.includes('/escuela')  && path.length <= 8 ? "active" : "inactive"}> Todos </Link>
        </MenuStyled>
    );
}

function MenuEquipo() {

    const path = window.location.pathname;
    const navigate = useNavigate();

    const addHandle = (e) => {
        e.preventDefault();
        if (window.confirm("Se borrará cualquier cambio no guardado. ¿Desea continuar?")) {
            window.localStorage.removeItem('draftjs-content');
            navigate("/equipo/add")
            navigate(0)
        }
    }

    return (
        <MenuStyled>
            <Link to="" onClick={addHandle} id={path.includes('/equipo/add') ? "active" : "inactive"}> Añadir</Link>
        </MenuStyled>
    );
}

function SubMenu() {

    const path = window.location.pathname;
    const dispatch = useDispatch();

    return (
        <MenuStyled>
            <Link to="" onClick={() => dispatch(sortCreacionNew())}> Nuevas </Link>
            <Link to="" onClick={() => dispatch(sortCreacionOld())}> Antiguas </Link>
        </MenuStyled>
    );
}


export default function Menu() {
    const path = window.location.pathname;
    const dispatch = useAuthDispatch();
    return (
        <>  
            <button onClick={()=>logout(dispatch)}>Cerrar sesion</button>
            <MenuStyled>
                <Link to="/sala/now" id={path.includes('sala') ? "active" : "inactive"}> Sala </Link>
                <Link to="/escuela" id={path.includes('escuela') ? "active" : "inactive"}> Escuela </Link>
                <Link to="/equipo" id={path.includes('equipo') ? "active" : "inactive"}> Equipo </Link>
                <Link to="/compras" id={path.includes('compras') ? "active" : "inactive"}> Compras </Link>
            </MenuStyled>
            {path.includes('sala') ?
                <MenuSala /> :
                path.includes('escuela') ?
                    <MenuEscuela /> :
                    path.includes('equipo') && <MenuEquipo />}
            
        </>
    );
}

const MenuStyled = styled.nav`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
font-size: 1.3vw;
width: 95%;
a{
    margin-top: 0.3vw;
    color: black;
    padding-inline: 1vw;
}
 #active{
    color: #f46d2b;
    border: 0.1vw solid #f46d2b;
    border-radius: 5px;
 }
 #inactive{
    color: black;
 }
`