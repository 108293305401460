import { useEffect, useState } from 'react';
import { postAPI, putAPI } from '../../api';
import TextEditor from '../TextEditor';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useNavigate } from 'react-router-dom';
import { FormStyled } from '../Layout';

export default function App({ customValues, editar }) {

  const navigate = useNavigate();

  const [fileS3, setFileS3] = useState(customValues.cartel || '');
  const [subiendoCurso, setSubiendoCurso] = useState(false)

  // Cartel Upload Component
  const UploadImage = () => {

    const [file, setFile] = useState();
    const [loading, setLoading] = useState("Sin imagen");

    const submit = async event => {
      event.preventDefault()
      setLoading("Subiendo imagen...");
      const result = await postImage({ image: file })
      setFileS3(result);
      return result;
    }

    return (
      <div className="upload-image">
        <div>
          <input key="input1" onChange={(e) => { setFile(e.target.files[0]) }} type="file" accept="image/*"></input>
          <button id="btn-image" onClick={submit}>Subir imagen</button>
        </div>
        <img src={fileS3}  width="500rem"></img>
        <span>{!fileS3 && loading}</span>
      </div>
    );
  }

  async function postImage({ image }) {
    const formData = new FormData();
    formData.append("image", image)
    return await postAPI('upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  // SUBMIT
  const onSubmit = async values => {

    values.cartel = fileS3;
    values.descripcion = window.localStorage.getItem('draftjs-content');

    let response = 'Sin respuesta';
    setSubiendoCurso(true);
    if (!editar) {
      response = await postAPI('Cursos', values);
    } else {
      response = await putAPI('Cursos', values.idMongo, values);
    }
    setSubiendoCurso(false);
    alert(JSON.stringify(response));
    navigate(0)
  }

  // Validation
  const required = value => (value ? undefined : ' Obligatorio')
  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  return (
    <FormStyled>
      <Form
        onSubmit={onSubmit}
        initialValues={customValues}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, values }) => (

          <form onSubmit={handleSubmit}>

            <div className='input-custom'>
              <h3>Cartel</h3>
              <Field name="Cartel" component={UploadImage} />
            </div>


            <div className='input-custom'>
              <h3>Nombre</h3>
              <Field
                name="nombre"
                validate={composeValidators(required)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Nombre curso" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>

              <h3>Descripcion</h3>
            <div className='input-sinopsis'>
              <TextEditor editorState={customValues.editorState} />
            </div>

            <div className='input-custom'>
              <h3>Profesores</h3>
              <FieldArray name="profesores">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <div>
                          <Field name={name} component="input" />
                          <button type="button" onClick={() => fields.remove(index)}>
                            ❌
                          </button>
                        </div>

                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => fields.push('')}
                    >
                      ➕
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            <div className='input-custom'>
              <h3>Tipo</h3>
              <Field name="categoria" component="select">
                <option value="curso">Curso</option>
                <option value="taller">Taller</option>
              </Field>
            </div>
            <button id="btn-submit" type="submit" disabled={subiendoCurso}>{editar ? <span>Guardar cambios</span> : <span>Añadir Curso</span>}</button>
          </form>
        )}
      />
    </FormStyled>
  );
}