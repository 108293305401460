import { useEffect, useState } from 'react';
import { postAPI, putAPI } from '../../api';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useNavigate } from 'react-router-dom';
import { FormStyled } from '../Layout';
import { Modal } from '../Modal';
import { useSelector } from 'react-redux';
import { selector } from '../../redux/slices/ComprasSlice';
import { fetchObra } from '../../redux/slices/ObrasSlice';

export default function CompraForm({ customValues, editar }) {

  const navigate = useNavigate();
  const [subiendoCompra, setSubiendoCompra] = useState(false)


  // SUBMIT
  const onSubmit = async values => {

    let response = 'Sin respuesta';
    setSubiendoCompra(true);
    if (!editar) {
      response = await postAPI('compras', values);
    } else {
      response = await putAPI('compras', values.idMongo, values);
    }
    setSubiendoCompra(false);
    alert(response == 'Sin respuesta' || 'Operación realizada correctamente');
    navigate(0)
  }

  return (
    <FormStyled>
      <Form
        onSubmit={onSubmit}
        initialValues={customValues}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, values }) => (

          <form onSubmit={handleSubmit}>

            {customValues?.estado?.includes("CONFIRMADA")
              && <div className='input-custom'>
                <h3>Devolución</h3>
                <button style={{ fontSize: 28, border: '1px black solid' }} onClick={(e) => {
                  e.preventDefault();
                  navigate('devolucion')
                }}>Tramitar devolucion</button>
              </div>}
            <div className='input-custom'>
              <h3>Estado</h3>
              <Field name="estado" component="select">
                <option value="CONFIRMADA">Confirmada</option>
                <option value="Pendiente de pago">Pendiente de pago</option>
                <option value="Devolucion solicitada">Devolucion solicitada</option>
                <option value="Devolucion confirmada">Devolucion confirmada</option>
                <option value="Sin confirmar">Sin confirmar</option>
                <option value="Todas">Todas</option>
              </Field>
            </div>

            <div className='input-custom'>
              <h3>Nº Entradas</h3>
              {values.cantidad && <p>{values.cantidad}</p>}
            </div>

            <div className='input-custom'>
              <h3>Email</h3>
              <span>{values.email}</span>
            </div>

            <div className='input-custom'>
              <h3>Fecha compra</h3>
              {values.fechaCompra && <p>{
                new Date(values.fechaCompra).toLocaleDateString("es-ES", {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}</p>}
            </div>

            <div className='input-custom'>
              <h3>Notas del comprador</h3>
              {values.notasEspeciales && <p>{values.notasEspeciales}</p>}
            </div>

            <div className='input-custom'>
              <h3>QR</h3>
              {values.qrImageS3 && <img src={`data:image/svg+xml;base64,${values.qrImageS3}`} alt="QR Code" width="400px" height="400px" />}
            </div>




            <div className='input-custom'>
              <h3>Fecha</h3>
              <span>{
                new Date(values.fechaObra).toLocaleDateString("es-ES", {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}</span>
            </div>



            <button id="btn-submit" type="submit" disabled={subiendoCompra}>{editar ? <span>Guardar cambios</span> : <span>Añadir  Compra</span>}</button>
          </form>
        )}
      />
    </FormStyled>
  );
} 