import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { EditorStyled } from './Editor.styled';
import { postAPI } from '../../api';

async function uploadImage(file) {
  const formData = new FormData();
  formData.append("image", file)
  // Upload to API
  const result = await postAPI('upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  // Custom object for DrafJS
  const data = { data: { link: result } }
  return data;
}

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (this.props.editorState) {

      let contentState;
      const containsHTMLTags = /<[^>]*>/.test(this.props.editorState);

      if (containsHTMLTags) {
        // Si contiene etiquetas HTML, quitarlas y crear ContentState a partir del texto sin formato.
        const strippedText = this.stripHTMLTags(this.props.editorState);
        contentState = ContentState.createFromText(strippedText);
      } else if (this.isValidJSON(this.props.editorState)) {
        try {
          // Intenta parsear como JSON y convertir a ContentState
          const parsedData = JSON.parse(this.props.editorState);
          contentState = convertFromRaw(parsedData);
        } catch (error) {
          console.error("Error parsing editorState:", error);
        }
      } else {
        // Si no es ni HTML ni JSON válido, tratar como texto sin formato.
        contentState = ContentState.createFromText(this.props.editorState);
      }

      if (contentState) {
        this.state.editorState = EditorState.createWithContent(contentState);
        this.saveContent(this.state.editorState.getCurrentContent());
      }
    }
  }

  isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  stripHTMLTags(input) {
    return input.replace(/<[^>]+>/g, '');
  }

  saveContent = (content) => {
    window.localStorage.setItem('draftjs-content', JSON.stringify(convertToRaw(content)));
  }

  onEditorStateChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    this.saveContent(contentState);
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    return <EditorStyled>
      <Editor
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: uploadImage, alt: { present: true, mandatory: false } },
        }}
      />
    </EditorStyled>
  }
}

export default TextEditor;