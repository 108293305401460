import Layout, { CardStyled } from '../../components/Layout';
import { fetchEquipo, selector } from '../../redux/slices/EquipoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAPI } from '../../api';

export default function Equipo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Equipo, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchEquipo());
    }, [])
    
    return (
        <Layout>
            {!loading ? <Table/> : <h3>Cargando...</h3>}
        </Layout>
    );

    function openEdit(e, id){
        e.preventDefault();
        navigate(`/equipo/${id}`)
    }

    async function handleDelete(e, id) {
        e.preventDefault();
        if (window.confirm("Está operación no se puede deshacer. ¿Desea continuar?")) {
            await deleteAPI('equipo', id);
        }
        navigate(0)
    }

    function Card({profesor}){
        const {nombre} = profesor;
        return (
            <CardStyled onClick={(e) => {openEdit(e, profesor._id)}}>
                <span>{nombre}</span>
                <button
                    onClick={(e) => handleDelete(e, profesor._id)}
                    style={{ width: "5%" }}>❌</button>
            </CardStyled>
        )
    }

    function Table(){
        return Equipo.map((profesor, index) => {
            return <Card key={index} profesor={profesor}/>
        })
    }
    
}