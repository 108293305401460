import Layout from "../../../components/Layout";
import ProfesorForm from "../../../components/ProfesorForm"

function App() {
  const customValues = {
  };
  return (
    <Layout>
      <ProfesorForm customValues={customValues} editar={false} />
    </Layout>
  );
}

export default App;