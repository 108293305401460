import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { fetchCompra, selector } from '../../../redux/slices/ComprasSlice';
import CompraForm from '../../../components/CompraForm';

// let editorState = {"blocks":[{"key":"9bjtg","text":"Supertesting strong chico malo","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}

export default function EditCompra() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { compra, loading } = useSelector(selector);

  useEffect(() => {
    dispatch(fetchCompra(id))
  }, []);

  const customValues = {
    idMongo: compra?._id,
    fechaObra: compra?.fechaObra,
    email: compra?.email,
    tituloObra: compra?.tituloObra,
    fechas: compra?.fechas,
    qrImageS3: compra?.qrImageS3,
    notasEspeciales: compra?.notasEspeciales,
    cantidad: compra?.cantidad,
    paymentIntentId: compra.paymentIntent?.id,
    fechaCompra: compra.paymentIntent?.created,
    estado: compra?.estado || "Sin confirmar",
  }

  return (
    <Layout>
      {loading ? <h3>Cargando...</h3> : <CompraForm customValues={customValues} editar={true} />}
    </Layout>
  );

}

/**
 * {
 * "_id":{"$oid":"623e1b1c75df1415753da15b"},
 * "fechaObra":1648328400000,
 * "email":"test@gmonzon.com",
 * "idObra":"6230e667db0753766fe0c61e",
 * "tituloObra":"Nueva Obra 1",
 * "qrImageS3":"https://lausinabucket.s3.amazonaws.com/pi_3KhJ1bFSfVapmcnV1TFkp61a.svg",
 * "cantidad":1,
 * "paymentIntent":{
 *      "id":"pi_3KhJ1bFSfVapmcnV1TFkp61a",
 *      "object":"payment_intent",
 *       "amount":1300,"amount_capturable":0,"amount_received":0,"application":null,"application_fee_amount":null,"automatic_payment_methods":{"enabled":true},"canceled_at":null,"cancellation_reason":null,"capture_method":"automatic","charges":{"object":"list","data":[],"has_more":false,"total_count":0,"url":"/v1/charges?payment_intent=pi_3KhJ1bFSfVapmcnV1TFkp61a"},"client_secret":"pi_3KhJ1bFSfVapmcnV1TFkp61a_secret_lHenJNkn3l9se3uJiygVfYi7X","confirmation_method":"automatic","created":1648237339,"currency":"eur","customer":null,"description":null,"invoice":null,"last_payment_error":null,"livemode":false,"next_action":null,"on_behalf_of":null,"payment_method":null,"payment_method_options":{"card":{"installments":null,"mandate_options":null,"network":null,"request_three_d_secure":"automatic"}},"payment_method_types":["card"],"processing":null,"receipt_email":null,"review":null,"setup_future_usage":null,"shipping":null,"source":null,"statement_descriptor":null,"statement_descriptor_suffix":null,"status":"requires_payment_method","transfer_data":null,"transfer_group":null},"__v":0}
 */