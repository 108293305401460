import { useState } from 'react';
import { postAPI, putAPI } from '../../api';
import TextEditor from '../TextEditor';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Fechas from '../Fechas';
import { useNavigate } from 'react-router-dom';
import { FormStyled } from '../Layout';

export default function App({ customValues, editar }) {

  const navigate = useNavigate();

  const [fileS3, setFileS3] = useState(customValues.cartel || '');

  const [subiendoObra, setSubiendoObra] = useState(false)

  // Cartel Upload Component
  const UploadImage = () => {

    const [file, setFile] = useState();
    const [loading, setLoading] = useState("Sin imagen");

    const submit = async event => {
      event.preventDefault()
      setLoading("Subiendo imagen...");
      const result = await postImage({ image: file })
      setFileS3(result);
      return result;
    }

    return (
      <div className="upload-image">
        <div>
          <input key="input1" onChange={(e) => { setFile(e.target.files[0]) }} type="file" accept="image/*" ></input>
          <button id="btn-image" onClick={submit}>Subir imagen</button>
        </div>
        <img src={fileS3} width="500rem"></img>
        <span>{!fileS3 && loading}</span>
      </div>
    );
  }

  async function postImage({ image }) {
    const formData = new FormData();
    formData.append("image", image)
    return await postAPI('upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  /**
   ********** SUBMIT
   */
  const onSubmit = async values => {

    values.cartel = fileS3;
    values.sinopsis = window.localStorage.getItem('draftjs-content');
    values.esNueva = true;

    let response = 'Sin respuesta';
    setSubiendoObra(true);
    if (!editar) {
      response = await postAPI('obras', values);
    } else {
      response = await putAPI('obras', values.idMongo, values);
    }
    setSubiendoObra(false);

    alert("Cambios registrados");
    navigate(0)
  }
  
  // Validation
  const required = value => (value ? undefined : ' Obligatorio')
  const mustBeNumber = value => (isNaN(value) ? ' Sólo formato numérico' : undefined)
  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  return (
    <FormStyled>
      <Form
        onSubmit={onSubmit}
        initialValues={customValues}
        mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push, pop, remove }
          },
          values }) => (
            
            <form onSubmit={handleSubmit}>

            <div className='input-custom'>
              <h3>Cartel</h3>
              <Field name="Cartel" component={UploadImage} />
            </div>


            <div className='input-custom'>
              <h3>Titulo</h3>
              <Field
                name="titulo"
                validate={composeValidators(required)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Titulo" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <><h3>Sinopsis</h3>
              <div className='input-sinopsis'>
                <TextEditor editorState={customValues.editorState} esNueva={customValues.esNueva}/>
              </div></>


            <div className='input-custom'>
              <h3>Autores</h3>
              <FieldArray name="autores">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <div>
                          <Field name={name} component="input" />
                          <button type="button" onClick={() => fields.remove(index)}>
                            ❌
                          </button>
                        </div>

                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => fields.push('')}
                    >
                      ➕
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            <div className='input-custom'>
              <h3>Directores</h3>
              <FieldArray name="direccion">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <div>
                          <Field name={name} component="input" />
                          <button type="button" onClick={() => fields.remove(index)}>
                            ❌
                          </button>
                        </div>

                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => fields.push('')}
                    >
                      ➕
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            <div className='input-custom'>
              <h3>Compañia</h3>
              <Field name="compania" component="input" placeholder="Compañia" />
            </div>

            <div className='input-custom'>
              <h3>Actores</h3>
              <FieldArray name="actores">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <div>
                          <Field name={name} component="input" />
                          <button type="button" onClick={() => fields.remove(index)}>
                            ❌
                          </button>
                        </div>

                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => fields.push('')}
                    >
                      ➕
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            {<Field name="fechas" component={Fechas} push={push} remove={remove} />}

            <div className='input-custom'>
              <h3>Duración (minutos)</h3>
              <Field
                name="duracionMinutos"
                validate={composeValidators(required, mustBeNumber)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Duración (minutos)"  id="input-num"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>


            <div className='input-custom'>
              <h3>Precio Principal</h3>
              <Field
                name="precio"
                validate={composeValidators(required, mustBeNumber)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Precio Principal"  id="input-num"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>

            <div className='input-custom'>
              <h3>Precio Infantil</h3>
              <Field
                name="precio2"
                validate={composeValidators(required, mustBeNumber)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Precio Infantil"  id="input-num"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>

            <div className='input-custom'>
              <h3>Precio Descuento Festival</h3>
              <Field
                name="precio3"
                validate={composeValidators(required, mustBeNumber)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Precio Festival"  id="input-num"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>

            <div className='input-custom'>
              <h3>Nº Máximo de entradas</h3>
              <Field
                name="maxEntradas"
                validate={composeValidators(required, mustBeNumber)}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Nº Máximo de entradas"  id="input-num"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>

            <div className='input-custom'>
              <h3>Prensa</h3>
              <FieldArray name="prensa">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <div>
                          <Field name={name} component="input" />
                          <button type="button" onClick={() => fields.remove(index)}>
                            ❌
                          </button>
                        </div>

                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => fields.push('')}
                    >
                      ➕
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className='input-custom'>
              <h3>Publicada</h3>
              <Field name="publicada" component="select">
                <option value={true}>💚 SI</option>
                <option value={false}>❌ NO</option>
              </Field>
            </div>

            <button id="btn-submit" type="submit" disabled={subiendoObra}>{editar ? <span>Guardar cambios</span> : <span>Nueva obra</span>}</button>
          </form>
        )}
      />
    </FormStyled>
  );
}