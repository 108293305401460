import Layout, { CardStyled } from '../../../components/Layout';
import { fetchTalleres, selector } from '../../../redux/slices/CursosSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAPI } from '../../../api';

export default function Talleres() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { Talleres, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchTalleres())
    }, [])

    return (
        <Layout>
            {loading ? <h1>Cargando...</h1> : <Table />}
        </Layout>
    );

    async function handleDelete(e, id) {
        e.preventDefault();
        if (window.confirm("Está operación no se puede deshacer. ¿Desea continuar?")) {
            await deleteAPI('cursos', id);
        }
        navigate('/escuela/talleres')
    }

    function openEdit(e, id) {
        e.preventDefault();
        navigate(`/escuela/${id}`)
    }

    function Card({ curso }) {
        const { nombre } = curso;
        return (
            <CardStyled onClick={(e) => { openEdit(e, curso._id) }}>
                <span>{nombre}</span>
                <button
                    onClick={(e) => handleDelete(e, curso._id)}
                    style={{ width: "5%" }}>❌</button>
            </CardStyled>
        )
    }

    function Table() {
        return Talleres.map((curso, index) => {
            return <Card key={index} curso={curso} />
        })
    }

}