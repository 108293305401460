import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { fetchProfesor, selector } from '../../../redux/slices/EquipoSlice';
import ProfesorForm from '../../../components/ProfesorForm'

// let editorState = {"blocks":[{"key":"9bjtg","text":"Supertesting strong chico malo","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}

export default function App() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { profesor, loading } = useSelector(selector);

  useEffect(() => {
    dispatch(fetchProfesor(id))
  }, []);

  const customValues = {
    idMongo: profesor?._id,
    nombre: profesor?.nombre,
    editorState: profesor?.descripcion,
    cartel: profesor?.cartel,
  }

  return (
    <Layout>
      {loading ? <h3>Cargando...</h3> : <ProfesorForm customValues={customValues} editar={true} />}
    </Layout>
  );

}