import { useEffect, useState } from "react"
import { postAPI } from "../../api"
import { fetchCompra, fetchCompras, selector } from "../../redux/slices/ComprasSlice"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate,  useParams } from "react-router"

const Devolucion = () => {
    const [tipoDevolucion, setTipoDevolucion] = useState("esta compra")
    const [fechaSeleccionada, setFechaSeleccionada] = useState(undefined)
    const [comprasEnFechaSeleccionada, setComprasEnFechaSeleccionada] = useState(undefined)
    const [resStripe, setResStripe] = useState(undefined)
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { compra, compras, loading } = useSelector(selector);
    const { fechas } = compra;
    const { id } = useParams();

    useEffect(() => {
        dispatch(fetchCompras());
        dispatch(fetchCompra(id))
    }, [id])

    useEffect(() => {
        const arr = compras?.filter(compra => {
            // Condición original
            return compra.fechaObra === fechaSeleccionada && compra.estado === "CONFIRMADA" && compra.idObra === id;
        });
        
        const arr2 = arr.map(c=> c.paymentId)
        setComprasEnFechaSeleccionada(arr2)
    }, [fechaSeleccionada])

    const onRefund = async (e) => {
        e.preventDefault();
        if(tipoDevolucion === "todas fecha" && comprasEnFechaSeleccionada?.length > 0) {
            const responses = await Promise.all(comprasEnFechaSeleccionada.map(async pi => {
                return await postAPI('create-refund', { paymentIntentId: pi });
            }));
            const results = responses?.map(r=> r.refund.status)
            setResStripe(results)
        } else {
            if (!compra?.paymentIntent?.id) alert("paymentId null")
            const stripeResponse = await postAPI('create-refund', { paymentIntentId: compra?.paymentIntent?.id })
            if (stripeResponse) setResStripe(stripeResponse)
        }
    }

    return (
        <div>
            <h1>Devolucion</h1>
            <span>Email asociado a compra: {compra.email}</span><br></br>
            <span>Importe: {compra.paymentIntent.amount / 100}€</span><br></br>
            <span>Estado: {compra.estado}</span><br></br>
            <span>Fecha de la función de esta compra: {new Date(compra?.fechaObra).toLocaleDateString("es-ES", {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}</span>
            <h3>Selecciona una opción</h3>
            <select value={tipoDevolucion} onChange={(e) => setTipoDevolucion(e.target.value)}>
                <option value="esta fecha">Esta compra</option>
                <option value="todas fecha">Todas las compras para determinada fecha</option>
            </select>
            {tipoDevolucion === "todas fecha"
                && <div><select onChange={(e) => setFechaSeleccionada(+e.target.value)} value={fechaSeleccionada} id="fechas-select">
                    {fechas?.map((fecha, index) => {
                        return (<option key={index} value={fecha.fecha}>{
                            new Date(fecha.fecha).toLocaleDateString("es-ES", {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}</option>);
                    })}
                </select>
                {comprasEnFechaSeleccionada?.length > 0 && <p>Se procederá a devolver {comprasEnFechaSeleccionada?.length} compras. Está operación no se puede revertir</p>}
                {comprasEnFechaSeleccionada?.length === 0 && <p>Sin compras en la fecha seleccionada</p>}
                </div>}
            {resStripe?.refund &&
                <><h3>Devolucion solicitada correctamente, confirme el estado de esta compra pasados unos minutos</h3>
                    <p>Importe: {resStripe.refund?.amount / 100}{resStripe.refund?.currency}</p></>}
            <button onClick={onRefund}>Confirmar devolución</button>
            <button onClick={(e)=>{e.preventDefault();navigate(-2)}}>Volver a compras</button>
            <pre>{JSON.stringify(resStripe, null, 2)}</pre>
        </div>
    );
}
export default Devolucion;


const data = {
    amount: 1400,
    balance_transaction: "txn_3O46WAFSfVapmcnV1vc5VWyL",
    charge: "ch_3O46WAFSfVapmcnV1c5tdObL",
    created: 1698077110,
    currency: "eur",
    id: "re_3O46WAFSfVapmcnV1c6k4awd",
    metadata: {},
    object: "refund",
    payment_intent: "pi_3O46WAFSfVapmcnV1WCfoT5g",
    reason: null,
    receipt_number: null,
    source_transfer_reversal: null,
    status: "succeeded",
    transfer_reversal: null
};

const resStripeError = {
    error: "Charge ch_3O46WAFSfVapmcnV1c5tdObL has already been refunded."
}