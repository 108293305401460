import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { fetchCurso, selector } from '../../../redux/slices/CursosSlice';
import CursoForm from '../../../components/CursoForm'

// let editorState = {"blocks":[{"key":"9bjtg","text":"Supertesting strong chico malo","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}

export default function App() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { curso, loading } = useSelector(selector);

  useEffect(() => {
    dispatch(fetchCurso(id))
  }, []);

  const customValues = {
    idMongo: curso._id,
    nombre: curso.nombre,
    editorState: curso.descripcion,
    cartel: curso.cartel,
    profesores: curso.profesores,
    categoria: curso.categoria,
  }

  return (
    <Layout>
      {loading ? <h3>Cargando...</h3> : <CursoForm customValues={customValues} editar={true} />}
    </Layout>
  );

}