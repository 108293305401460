import Layout from "../../../components/Layout";
import ObraForm from "../../../components/ObraForm"

function App() {

  const customValues = {
    precio: process.env.REACT_APP_LA_USINA_DEFAULT_PRICE,
    precio2: process.env.REACT_APP_LA_USINA_DEFAULT_PRICE2,
    precio3: process.env.REACT_APP_LA_USINA_DEFAULT_PRICE3,
    maxEntradas: process.env.REACT_APP_LA_USINA_MAX_ENTRADAS,
    duracionMinutos: process.env.REACT_APP_LA_USINA_DURACION_MINUTOS,
    esNueva: true,
    publicada: true,
  };

  return (
    <Layout>
      <ObraForm customValues={customValues} editar={false} />
    </Layout>
  );
}

export default App;