import Layout from "../../../components/Layout";
import CursoForm from "../../../components/CursoForm"

function App() {
  const customValues = {
    categoria: 'curso'
  };
  return (
    <Layout>
      <CursoForm customValues={customValues} editar={false} />
    </Layout>
  );
}

export default App;