import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../api";
import { fetchObrasList } from "./ObrasSlice";

export const fetchCompras = createAsyncThunk(
  "Compras/fetchCompras",
  async () => {
    const compras = await getAPI('compras');
    const ObrasList = await getAPI('obras'); 

    return compras.map(compra => {
      const obraSeleccionada = ObrasList?.find(obra => obra._id === compra.idObra);
      return {
        ...compra,
        tituloObra: obraSeleccionada ? obraSeleccionada.titulo : 'Desconocido'
      }
    });
  }
);

export const fetchCompra = createAsyncThunk(
  "Compras/fetchCompra",
  async (id, thunkAPI) => {
    return await getAPI(`compras/${id}`)
      .then((data) => {
        return data
      });
  }
);


export const ComprasSlice = createSlice({
  name: "compras",
  initialState: {
    compras: [],
    loading: false,
    compra: {},
  },
  reducers: {},
  extraReducers: {

    [fetchCompras.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompras.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // añadir título de la obra a cada compra
      state.compras = payload;
    },
    [fetchCompras.rejected]: (state) => {
      state.loading = true;
    },

    [fetchCompra.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompra.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.compra = payload;
    },
    [fetchCompra.rejected]: (state) => {
      state.loading = true;
    },

  },
});

export const selector = (state) => state.compras;

export default ComprasSlice.reducer;
