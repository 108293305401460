import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAPI} from "../../api";

export const fetchUsersList = createAsyncThunk(
    "rooms/fetchUsersList",
    async () => {
       return await getAPI('users')
          .then((data) => {return data});
    }
);

export const UsersSlice = createSlice({
  name: "users",
  initialState: {
    usersList: [],
    listAux: [],
    loading: false,
  },
  reducers: {
/*       sort: (state, {payload}) => {
          switch (payload){
             case "new":
                state.listAux = state.listAux.sort((a, b) => {
                    let date1 = new Date(a.startDate)
                    let date2 = new Date(b.startDate)
                    return date1 - date2;
                })
                break;
             case "old":
                state.listAux = state.listAux.sort((a, b) => {
                    let date1 = new Date(a.startDate)
                    let date2 = new Date(b.startDate)
                    return date2 - date1;
                })
                break; 
             default:
                console.log("sort error")
          }
      }, */
      all: (state) => {
          state.listAux = state.usersList;
      },
/*       filter: (state, {payload}) => {
          state.listAux = state.usersList.filter(u => u.status === payload)
      } */
  },
  extraReducers: {
    [fetchUsersList.pending]: (state) => {
      state.loading = true;
    },
    [fetchUsersList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listAux = payload;
      state.usersList = payload;
    },
    [fetchUsersList.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selector = (state) => state.users;

export const {all} = UsersSlice.actions;

export default UsersSlice.reducer;
