import styled from "styled-components";

export const Modal = ({ isOpen, children, onClose }) => {
    if (!isOpen) return null;

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={e => e.stopPropagation()}>
                {children}
            </ModalContent>
        </ModalOverlay>
    );
};

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

// Estilos para el contenido del modal
const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 70%;
    max-width: 500px;
`;