import obrasReducer from "./slices/ObrasSlice";
import usersReducer from "./slices/UsersSlice";
import cursosReducer from "./slices/CursosSlice";
import equipoReducer from "./slices/EquipoSlice";
import comprasReducer from "./slices/ComprasSlice";

import { combineReducers } from "@reduxjs/toolkit";

export default combineReducers({
  obras: obrasReducer,
  users: usersReducer,
  cursos: cursosReducer,
  compras: comprasReducer,
  equipo: equipoReducer,
});
