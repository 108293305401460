import Menu from '../../components/Menu';
import styled, { createGlobalStyle } from "styled-components";


export default function Layout({children}){

    return (
            <Container>
                <GlobalStyle/>
               <Menu/>
               <HrStyled/>
                    {children}
            </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

#btn-submit{
    margin: 2vw;
    color: black;
    border: 0.2vw solid #f46d2b;
    font-size: 2.5vw;
    :hover{
        background-color: #f46d2b;
        color: white;
        border: 0.2vw solid black;
    }
}

#btn-image{
    color: #f46d2b;
    font-size: 1vw;
    padding: 0.2vw;
    margin: 0.5vw;
    border: 0.1vw solid #f46d2b;
    :hover{
        cursor: pointer;
        background-color: #f46d2b;
        color: white;
        border: 0.1vw solid #f46d2b;
    }
}

.head-compras {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    button {
        border: 1px solid orange;
    }
}

`


export const GlobalStyle = createGlobalStyle`
html, #root, #app {
    min-height: 100% !important;
    height: 100%;
    width: 100%;
  }
  img{
      margin: 1%;
  }
  h3{
      color: #f46d2b;
      margin-top: 4vw;
  }
  a{
    text-decoration: none;
  }
  button{
      background: none;
      border: none;
      margin: 0.1vw;
      :hover{
          cursor:pointer;
      }
  }
  form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100% !important;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x:hidden;
  }
`

export const FormStyled = styled.div`
font-size: 1.2vw;

input, select{
    font-size: 1.2vw;
    margin: 0.2vw;
}

#input-num{
    width: 3vw;
}

.input-custom{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.input-curso{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 95%;
}


.input-sinopsis{
    font: unset;
    font-size: unset;
    font-family: unset;
    display: flex;
    max-width: 95%;
}

`

export const CardStyled = styled.div`
    font-size: 2rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: row;
    border: 1px solid black;  
    width: 95%;
    margin: 0.2rem; 
    padding: 0.2rem; 
    :hover{
        color: #f46d2b;
        cursor: pointer;
    }

    /* Media query para pantallas pequeñas */
    @media (max-width: 1024px) {
        flex-direction: column;
    }

    /* Media query para pantallas aún más pequeñas */
    @media (max-width: 768px) {
    }
`;

export const HeadStyled = styled.div`
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: row;
    border: 0.1vw solid black;
    width: 95%;
    margin: 0.2vw;
    padding: 0.2vw;
`

const HrStyled = styled.hr`
    border: solid #f46d2b;
    width: 100%;
    padding: 0;
    margin: 0.5vh;
`