import Layout, { CardStyled, HeadStyled, HrStyled } from '../../components/Layout';
import { fetchObrasList, selector } from '../../redux/slices/ObrasSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAPI } from '../../api';

export default function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ObrasList, loading } = useSelector(selector);
    const [searchText, setSearchText] = useState('');
    const [listFiltered, setListFiltered] = useState([]);

    useEffect(() => {
        dispatch(fetchObrasList());
    }, [])


    async function handleDelete(e, id) {
        e.preventDefault();
        if (window.confirm("Se borrará la función entera. Está operación no se puede deshacer. ¿Desea continuar?")) {
            await deleteAPI('obras', id);
        }
        navigate(0)
    }
    
    const onChangeSearchText = (e)=>{
        setSearchText(e.target.value);
    }

    useEffect(() => {
        const result = ObrasList.filter(obra =>
            obra.titulo.toLowerCase().includes(searchText.toLowerCase())
        );
        setListFiltered(result);
    }, [searchText, ObrasList]);

    return (
        <Layout>
            <input onChange={onChangeSearchText} placeholder='Buscar por título'></input>
            <HeadStyled>
                <span style={{ width: "92%" }}>Título</span>
                <span style={{ width: "8%" }}>Borrar</span>
            </HeadStyled>
            {!loading ? <TableObras /> : <h3>Cargando...</h3>}
        </Layout>
    );

    function openEdit(e, id) {
        e.preventDefault();
        navigate(`/sala/${id}`)
    }

    function Card({ obra }) {
        const { fechas, titulo, vendidas, reservas } = obra;
        const inicio = Math.min(fechas);
        const fin = Math.max(fechas);
        return (
            <CardStyled onClick={(e) => { openEdit(e, obra._id) }}>
                <span style={{ width: "95%" }}>{titulo}</span>
                <button
                    onClick={(e) => handleDelete(e, obra._id)}
                    style={{ width: "5%" }}>❌</button>
            </CardStyled>
        )
    }

    function TableObras() {
        if(searchText.length === 0) return ObrasList?.map((obra, index) => {
            return <Card key={index} obra={obra} />
        }) 
        else if (listFiltered.length > 0) return listFiltered.map((obra, index) => {
            return <Card key={index} obra={obra} />
        })
        else  return <h2>Sin obras que mostrar</h2>
    }

}